import loadable from '@loadable/component'

const routes = [{
    path: '/',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
  {
    path: '/course-center',
    exact: true,
    component: loadable(() => import('@/view/course-center/course-center')),
  },
  {
    path: '/about-us',
    exact: true,
    component: loadable(() => import('@/view/about-us/about-us')),
  },
  {
    path: '/course-detail',
    exact: true,
    component: loadable(() => import('@/view/course-detail/course-detail')),
  },
  {
    path: '/qiyu',
    exact: true,
    component: loadable(() => import('@/view/qiyu/qiyu')),
  },
  {
    path: '/whitePaper',
    exact: true,
    component: loadable(() => import('@/view/whitePaper'))
  },
  {
    path: '/commonProblems',
    exact: true,
    component: loadable(() => import('@/view/commonProblems'))
  }
]

export default routes
